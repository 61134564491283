.montserratFont {
  font-family: 'Montserrat', sans-serif !important;
}

.pinkBtn {
  background: #f92472;
  color: white !important;
  border: #f92472 !important;
}

.pinkBtn:focus {
  background: #f92472 !important;
  border: #f92472;
  color: white !important;
}

.pinkBtn:hover {
  background: #f92472 !important;
  border: #f92472;
  color: white !important;
  cursor: pointer;
}

.pinkBtn[disabled] {
  background: #a0a0a0;
}

.greenBtn {
  background: #00a58e;
  color: white !important;
  border: #00a58e !important;
}

.greenBtn:focus {
  background: #00a58e !important;
  border: #00a58e;
  color: white !important;
}

.greenBtn:hover {
  background: #00a58e !important;
  border: #00a58e;
  color: white !important;
  cursor: pointer;
}

.greenBtn[disabled] {
  background: #a0a0a0;
}

.backImg {
  width: 26px !important;
  height: 26px !important;
}

.fontSize14px {
  font-size: 14px !important;
}

.fontSize12px {
  font-size: 12px !important;
}

//Project Logs board
.project-logs-board {
  background-color: #f8f8f8;
  color: #000;
  max-height: 450px;
  overflow-y: scroll;
  border: 1px solid #d8d8d8;
}

.project-logs-board span {
  font-weight: bold;
}

.login-bg {
  background-image: url(../../public/branding/bg.png);
  background-size: 120vh;
  background-position: center;
  background-repeat: no-repeat;
}
.login-logo {
  background: #1e2645a6;
  border-radius: 5px;
  padding: 10px;
}

/* Monaco Editor selection styles */
.monaco-editor .selected-text {
  background-color: rgba(100, 150, 255, 0.5) !important; 
  color: white !important; 
}

//Memory Usage of nodes in cluster
.memory-tag {
  &.high-memory {
    background-color: #FF4D4D; 
    
    &.Theme-dark {
      background-color: #FF3B3B; 
    }
  }

  &.medium-memory {
    background-color: #FFC34D; 
    
    &.Theme-dark {
      background-color: #FF7A00;
    }
  }

  &.low-memory {
    background-color: #69DB7C; 
    
    &.Theme-dark {
      background-color: #1F7A2F; 
    }
  }
}

//Kubelet status div in nodes tab
.kubelet-div {
  font-size: 13px;
  font-weight: 500;
  color: #1A1A1A;
  border: 2px solid #6F6FAF;

  /* Dark Theme */
  &.Theme-dark {
    border-color: #A1A1CC;
  }

  /* Danger state */
  &.danger {
    background-color: #9B2D2D;
    border-color: #B20000;
    color: #E0E0E0;
  }

  /* Normal state */
  &.normal {
    background-color: #C2C2E0;
  }
}

.Theme-dark {
  // Main background color for dark theme
  background-color: #161616 !important;

  #root {
    // Main background color for dark theme
    background-color: #161616 !important;
  }

  // Console element for dark theme
  [data-method='info'] [data-type='string'] {
    color: #f8f8f8 !important;
  }
  [data-method='info'] span {
    color: #f8f8f8 !important;
  }

  [data-method='info'] {
    border-top: 1px solid rgb(36, 36, 36);
  }

  [data-method='info'] [data-type='string'] a {
    color: #4da6ff;
    &:hover {
      color: #99ccff;
      text-decoration: none;
    }
  }

  // Selected menu element for dark theme
  .ant-menu-item.ant-menu-item-selected {
    background-color: #404040;
    color: #f8f8f8 !important;
  }

  .ant-btn-primary{
    background-color: #2b8e7cc2;
    border-color: #00a58e;
    color: #ffffff;
  }

   .ant-alert-info {
    background-color: #00ff524a !important;
    border-color: #000000 !important
  }
  
   .ant-alert-error {
    background-color: #ff00004a !important;
    border-color: #000000 !important
  }
  
   .ant-alert-warning {
    background-color: #ffff004a !important;
    border-color: #000000 !important
  }

  .anticon-check-circle{
    color: green !important;
  }

  //Table elements

  .ant-table-cell a {
    color: white !important;
  }

  //message elements
  .ant-message-notice-content {
    background-color: #2c2f33 !important; 
    border: 1px solid #3a3d40 !important;
    color: #ffffff; 
  }

  .monaco-editor .selected-text,
.monaco-editor.vs-dark .selected-text {
  background-color: rgba(86, 156, 214, 0.4) !important; 
  color: #ffffff !important;
}

  // Cursor color for Editor in Dark Mode
  .monaco-editor .cursor {
    background-color: #FFFFFF !important; 
    border-color: #FFFFFF !important;
  }

  //Text in Tree View of Editor
  .ant-tree-node-selected {
    background-color: #00A58E !important; 
    color: #FFFFFF !important;          
  }

  .ant-tree-node-content-wrapper:hover {
    background-color: #007A65 !important; 
  }
  
}
